import React from 'react';
import ReactDOM from 'react-dom/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import App from './App';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

window.API_URL = 'https://www.exactchange.network/llm';
// window.API_URL = 'http://localhost:1337/llm';

root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </React.StrictMode>
);
